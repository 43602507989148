/**
 * Each section of the site has its own module. It probably also has
 * submodules, though this boilerplate is too simple to demonstrate it. Within
 * `src/app/home`, however, could exist several additional folders representing
 * additional modules that would then be listed as dependencies of this one.
 * For example, a `note` section could have the submodules `note.create`,
 * `note.delete`, `note.edit`, etc.
 *
 * Regardless, so long as dependencies are managed correctly, the build process
 * will automatically take take of the rest.
 *
 * The dependencies block here is also where component dependencies should be
 * specified, as shown below.
 */
 // TOOD: clarify API, failed route.. It's a bit more complicated
angular.module( 'sq.jobs.queue', [
  'sq.jobs.queue.component',
  'sq.user.auth',
  'sq.jobs.tracker.service',
  /* third party dependencies*/
])

/**
 * Each section or module of the site can also have its own routes. AngularJS
 * will handle ensuring they are all available at run-time, but splitting it
 * this way makes each module more "self-contained".
 */
/* Using id instead of _id because angular components don't resolve with underscores in place*/
//Use of :id? allows us to support both /queue/type/
.config(($routeProvider) => {
  $routeProvider
  .when('/queue/:type/:_id?', {
    template: `<sq-job-queue
    job-type='$resolve.jobType'
    id='$resolve.id'
    </sq-job-queue>`,
    title: 'Job Queue',
    reloadOnSearch: true,
    secureAsync: true,
    resolve : {
      jobType: ($route) => $route.current.params.type,
      id: ($route) => $route.current.params._id || $route.current.params.id,
      // provide nice loading bar
      jobs: (jobTracker) => jobTracker.initializeAsync(),
    }
  });
});

/**
 * And of course we define a controller for our routes in separate files
 */
 